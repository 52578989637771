import React from "react";
import "./Testimonials.css";
import StarIcon from "@material-ui/icons/Star";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
function Testimonials() {
  return (
    <div className="testimonials-container">
      <div className="testimonial">
        <div className="testimonial-top">
          <div className="testimonial-top-avatar">
            <img src="https://www.upwork.com/profile-portraits/c15Qj7US_nZMS40oMuPv320IXZoIAMGBbZQe4Z_6V79y2CB9aQWBUf5NlqdnMspuBK" alt=" " width="100%" height="auto" />
          </div>
          <div className="testimonial-top-rating">
            <p>Osama Khan</p>
            <p className="location-indicator">
            <LocationOnIcon />
            houston, United States</p>
            <div>
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
            </div>
          </div>
            <CheckCircleIcon className="tick-icon" />
        </div>
        <p className="testimonial-review">
          In the name of Allah, the Kind, the Merciful. Ritek has a high skill
          level in React and Javascript. Be sure to communicate your job
          clearly, and Ritek was accommodating. Many features were added, and he
          did not complain. He was patient, polite, and postive in his approach
          of fixing and adding features. If you need someone that knows React,
          then Ritek can do React. Thank you, Ritek. Alhamdulilah.
        </p>
        <div className="testimonial-logo-container">
            <img src="images/Upworklogo.png" alt='' width="100%" height="48px" />
        </div>
      </div>
      <div className="testimonial">
        <div className="testimonial-top">
          <div className="testimonial-top-avatar">
            <img src="https://www.upwork.com/profile-portraits/c147icdIg5TPrUX6t2ophjyTHf0bLRUl-3yU_OkWfD3Sz-DCaiPZpqMPZbIUUKwqly" alt=" " width="100%" height="auto" /></div>
          <div className="testimonial-top-rating">
            <p>Justin Bauer</p>
            <p className="location-indicator"> 
            <LocationOnIcon />
            Milwaukee, United States</p>
            <div>
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
            </div>
          </div>
            <CheckCircleIcon className="tick-icon" />
        </div>
        <p className="testimonial-review">
        Ritik was an awesome help developing a Facebook Messenger Webhook. Very
        thorough and had great communication. Looking forward to working with
        him in the future!
        </p>
        <div className="testimonial-logo-container">
            <img src="images/Upworklogo.png" alt='' width="100%" height="48px" />
        </div>
      </div>
    </div>
  );
}

export default Testimonials;

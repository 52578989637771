import React from "react";

function Tabcontent({ vid, image, title, headline, info, button, link }) {
  return (
    <div id={vid} className="tabContent">
      <div className="tabContent-img">
        <img src={image} alt="vehicle" />
      </div>
      <div className="tabContent-info">
        <h1>{title}</h1>
        <h4>{headline}</h4>
        <p>{info}</p>
        <button onClick={() => window.open(link)} className="tabContent-button">
          {button}
        </button>
      </div>
    </div>
  );
}

export default Tabcontent;

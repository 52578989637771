import React,{useState} from 'react'
import WorkCard from './WorkCard'
import Data from "./Data"
function Work() {
    const [cardList,setCardList]=useState(Data);
    return (
        <div id="projects" className="work">
            <div className="work-heading">
            <h1>Check Out Some Of My Work</h1>
            </div>
            <div className="work-list">
                {
                    cardList.map((card)=>(<WorkCard key={card.id} image={card.image} title={card.title} description={card.info} link={card.link} />))
                }
            </div>

        </div>
    )
}

export default Work

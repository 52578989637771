import React from "react";
import "./WorkCard.css";

function WorkCard({ image, title, description, link }) {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={image} alt=" " />
        </div>
        <div className="flip-card-back">
          <h1>{title}</h1>
          <p>{description}</p>
          <a className="demoLink" href={link}>
            Live Demo
          </a>
        </div>
      </div>
    </div>
  );
}

export default WorkCard;

import "./App.css";
import Header from "./components/Header";
import ImgSlider from "./components/Carousel";
import Work from "./components/Work";
import Footer from "./components/Footer";
import Tab from "./components/Tab/Tab";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
function App() {
  return (
    <div className="App">
      <div className="background-container"></div>
      <Header />
      <ImgSlider />
      <Work />
      <Tab />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

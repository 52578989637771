let data = [
  {
    id: "1",
    image: "images/c1.png",
    title: "Xpressbook",
    info: "Xpressbook is a social platform to share thought and to express.It has google OAUTH user authentication and level 6 of security and with hashing and salting of user password for authentication security and have mongodb database.This amazing website is made with HTML5, CSS, Bootstrap, nodejs, express, EJS, mongodb, mongoose, Jquery , javascript and REST API.",
    link: "http://xpressbook.herokuapp.com/",
  },
  {
    id: "2",
    image: "/images/c6.png",
    title: "Delhi COP",
    info: "Delhi COP is a police petroling and crime tracking platform with map and graph representation. Made with using REST API, react, node,mongodb, javascript, JWT, Socket.io ,and apache.",
    link: "http://delhicop.in/",
  },
  {
    id: "3",
    image: "/images/c4.png",
    title: "Eye Galaxy",
    info: "Eye glasses online store fully functioning and responsive build with react using react functional components, State-Hook, Effect Hook, material-ui, jQuery and firebase.",
    link: "https://eye-galaxy.web.app/",
  },
  {
    id: "4",
    image: "/images/c5.png",
    title: "Skillop",
    info: "skillop is a online learning platform with courses and tutorials with user authentication and user dashboard and with google OAUTH user authentication and level 6 of security and with hashing and salting of user password for authentication security and have mongodb database.Platform made with React, Node, mongodb, mongoose , javascript and REST API.",
    link: "https://skillop.in/",
  },
  {
    id: "5",
    image: "/images/1.png",
    title: "Ola 2.0",
    info: "Ola 2.0 online cab booking website multipage website with animations, smooth and remarkably fast, made using React, React-router, functional components, React-hooks, JavaScript, CSS, HTML5, Material-ui and Firebase.",
    link: "https://awesome-mirzakhani-ea275c.netlify.app/",
  },
  {
    id: "6",
    image: "/images/c2.png",
    title: "Amazon Clone",
    info: "Amazon clone with payment functionality using stripe connected using REST API and a user authenticationand google firebase database for storing user data.It is a full stack build made with using react, nodejs, express, javascript, firebase and material ui .",
    link: "https://clone-73dc7.web.app/",
  },

  // {
  //   id: "5",
  //   image: "images/c3.png",
  //   title: "Covid-19 Tracker",
  //   info: "Covid-19 tracker with real time data and live cases of recovered and deaths, with map and graph representation with country selection option with dark and with light theme. Made with using REST API, react, javascript ,and material-ui.",
  //   link: "https://covid-19-app-2-90d0a.web.app/",
  // },
  // {
  //   id: "6",
  //   image: "images/react-portfolio.png",
  //   title: "React Portfolio",
  //   info: "This beautiful and responsive portfolio with skills, contact me, education ,about, address and some other sections is made with using react, jquery, javascript, material ui and with CSS animation and custom styling.",
  //   link: "https://naughty-bohr-565c83.netlify.app/",
  // },
  // {
  //   id: "7",
  //   image: "/images/hotstar.png",
  //   title: "Disney+ HostStart Clone",
  //   info: "Disney+ Hotstart clone multipage, animations and remarkably attractive perfect design, fast and smooth ui . Made with using React ,Redux, react hooks, functional components,React-router, material-ui, javascript, CSS, HTML5 and Fiebase.",
  //   link: "https://clever-meitner-dbeba9.netlify.app/",
  // },
  // {
  //   id: "8",
  //   image: "images/4.png",
  //   title: "Airbnb Clone",
  //   info: "Airbnb Clone with search option and date picker. It is fully responsive and fast this beautiful UI is made with react,javascript, material-ui and uses react-hooks,react-filter and react-functional base components.",
  //   link: "https://airbnb-dbc6c.web.app/",
  // },
];

export default data;

import React from "react";
import { Instagram, GitHub, LinkedIn } from "@material-ui/icons";

function Header() {
  return (
    <div className="header">
      <h2 id="name-heading">Ritik Pal</h2>
      <div className="header-nav-link-container">
        <a href="#projects">Works</a>
        <a href="#resume">About</a>
        <a href="#contact">Contact</a>
      </div>
      <div className="header-social-icons-container">
        <a href="https://www.instagram.com/iamritikpal/">
          <Instagram className="header-social-icons" />
        </a>
        <a href="https://www.linkedin.com/in/ritik-pal-1a8255168/">
          <LinkedIn className="header-social-icons" />
        </a>
        <a href="https://github.com/RitikPal98">
          <GitHub className="header-social-icons" />
        </a>
      </div>
    </div>
  );
}

export default Header;

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function ImgSlider() {
    return (
        <Carousel 
        showArrows={true}
        showIndicators={false}
        showThumbs={false}
        dynamicHeight={true}
        infiniteLoop={true}
        interval={1700}
        autoPlay={true}>
        <div className="img-slider-container">
            <img src="images/1.png" />
        </div>
        <div className="img-slider-container">
            <img src="images/2.png" />
        </div>
        <div className="img-slider-container">
            <img src="images/c3.png" />
        </div>
        <div className="img-slider-container">
            <img src="images/4.png" />
        </div>
        <div className="img-slider-container">
            <img src="images/c2.png" />
        </div>
        <div className="img-slider-container">
            <img src="images/c11.png" />
        </div>
        <div className="img-slider-container">
            <img src="images/hotstar.png" />
        </div>
    </Carousel>
    )}
export default ImgSlider;

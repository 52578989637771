import React, { useState } from "react";
import Tabcontent from "./Tabcontent";
import "./Tab.css";
import TabLink from "./TabLink";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
function Tab() {
  const [tabno, setTabno] = useState(2);
  function showFleet(event, fleetName) {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabContent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByClassName("fleetTab-link");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].firstChild.className =
        tablinks[i].firstChild.className.replace("active");
      tablinks[i].childNodes[2].style.display = "none";
      tablinks[i].firstChild.childNodes[0].style.display = "block";
      // tablinks[i].firstChild.childNodes[1].style.transform="translateX(-120px)";
      tablinks[i].childNodes[1].style.color = "#999";
    }
    document.getElementById(fleetName).style.display = "flex";
    event.currentTarget.firstChild.className += " active";
    event.currentTarget.childNodes[2].style.display = "block";

    event.currentTarget.firstChild.firstChild.style.display = "none";
    // event.currentTarget.firstChild.childNodes[1].style.transform="translateX(1px)"
    event.currentTarget.childNodes[1].style.color = "white";
  }
  function buttonClick(e) {
    let i, tabcontent, targetElm;
    tabcontent = document.getElementsByClassName("tabContent");
    for (i = 0; i < tabcontent.length; i++) {
      if (tabcontent[i].style.display !== "none") {
        if (e.currentTarget.className === "prev-button") {
          if (i === 0) {
            targetElm = document.getElementById("tv4");
            targetElm.scrollIntoView();
            return document.getElementById("tv4").click();
          } else if (i >= 1) {
            targetElm = document.getElementById("tv" + i);
            targetElm.scrollIntoView();
            return document.getElementById("tv" + i).click();
          }
        } else if (e.currentTarget.className === "next-button") {
          if (i === 3) {
            targetElm = document.getElementById("tv1");
            targetElm.scrollIntoView();
            return document.getElementById("tv1").click();
          } else {
            targetElm = document.getElementById("tv" + (i + 2));
            targetElm.scrollIntoView();
            return document.getElementById("tv" + (i + 2)).click();
          }
        }
      }
    }
  }

  return (
    <div id="resume" className="fleetTab-container">
      <div className="tab-link-icon-container">
        <div onClick={(e) => buttonClick(e)} className="prev-button">
          <NavigateBeforeIcon />
        </div>
        <div id="scrollid" className="fleetTab tab">
          <TabLink
            icon1="images/avatar.svg"
            icon2="images/avatar2.svg"
            name="About"
            showFleet={showFleet}
            vid="v1"
          />
          <TabLink
            icon1="images/ti1.svg"
            icon2="images/ti11.svg"
            name="XpressBook"
            showFleet={showFleet}
            vid="v2"
          />
          <TabLink
            icon1="images/t2.svg"
            icon2="images/t22.svg"
            name="Amazon Clone"
            showFleet={showFleet}
            vid="v3"
          />
          <TabLink
            icon1="images/t3.svg"
            icon2="images/t33.svg"
            name="Ola Clone"
            showFleet={showFleet}
            vid="v4"
          />
        </div>
        <div onClick={(e) => buttonClick(e)} className="next-button">
          <NavigateNextIcon />
        </div>
      </div>
      <div className="tab-content-container-main">
        <div
          id="tabcontent-bottom-buttom1"
          onClick={(e) => buttonClick(e)}
          className="prev-button"
        >
          <NavigateBeforeIcon style={{ fontSize: "2.6rem" }} />
        </div>
        <div className="fleetTab-content-container">
          <Tabcontent
            vid="v1"
            image="images/me.jpg"
            title="Ritik Pal"
            headline="React and Nodejs Developer."
            info="
            Greetings and welcome to my page.
            My experience in Web development is more than 3 years.
Technologies that I use are : 
React, 
NodeJs, 
Express, 
MongoDB,
javascript, 
HTML5, 
CSS3, 
Bootstrap, 
Material-ui, 
Firebase, 
JQuery,
API integration, and
Git.
Besides, I possess great communication skills.
I am confident and actively seek out new technologies and stay up-to-date on industry trends and advancements.
I always give my best in achieving the goals, I am a fast Learner and constantly improving my skills.
Remember, I am the one guy who will be more even worried about the success of your project than you.

"
            button="Download Resume"
          />
          <Tabcontent
            vid="v2"
            image="images/c1.png"
            title="XpressBook"
            headline="Connects the world"
            info="Xpressbook is a social platform to share thought and to express.It has google OAUTH user authentication and level 6 of security and with hashing and salting of user password for authentication security and have mongodb database.This amazing website is made with HTML5, CSS, Bootstrap, nodejs, express, EJS, mongodb, mongoose, Jquery , javascript and REST API."
            button="Live Demo"
            link="https://xpressbook.onrender.com/"
          />
          <Tabcontent
            vid="v3"
            image="images/c2.png"
            title="Amazon Clone"
            headline="React ,Nodejs,Realtime Database, Router, Stripe, User Authontication, API and etc."
            info="Amazon clone with payment functionality using stripe connected using REST API and a user authenticationand google firebase database for storing user data.It is a full stack build made with using react, nodejs, express, javascript, firebase and material ui"
            button="Live Demo"
            link="https://clone-73dc7.web.app/"
          />
          <Tabcontent
            vid="v4"
            image="images/1.png"
            title="Ola 2.0"
            headline="React, Router, JavaScript, Animations etc."
            info="Ola 2.0 online cab booking website multipage website with animations, smooth and remarkably fast, made using React, React-router, functional components, React-hooks, JavaScript, CSS, HTML5, Material-ui and Firebase."
            button="Live Demo"
            link="https://awesome-mirzakhani-ea275c.netlify.app/"
          />
        </div>
        <div
          id="tabcontent-bottom-buttom2"
          onClick={(e) => buttonClick(e)}
          className="next-button"
        >
          <NavigateNextIcon style={{ fontSize: "2.6rem" }} />
        </div>
      </div>
    </div>
  );
}

export default Tab;

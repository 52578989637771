import { React, useState } from "react";
import "./Contact.css";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
function Contact() {
  let boxes = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
  ];
  // let i= 1;
  // while(i<=1000){
  //         boxes.push(i);
  //         i++;
  // }
  // console.log(boxes)

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  // console.log("message@@@@@",message)

  const handleClick = (e) => {
    e.preventDefault();
    setEmail("ritikpal098@gmail.com");
    window.open(`mailto:${email}?subject=${subject}&body=${name}: ${message}`);
  };

  return (
    <form onSubmit={handleClick} id="contact" className="contact">
      <div className="glow-blanket">
        {boxes.map((a) => {
          return boxes.map((box) => {
            return <div className="glow-box"></div>;
          });
        })}
      </div>
      {/*
      <div className="contact-input-container">
        <MailRoundedIcon style={{ fontSize: "4rem" }} />
      </div>
      <div className="contact-input-container">
        <h4>
          Name
          <span class="required">*</span>
        </h4>
        <input
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="contact-input-container">
        <h4>
          Email
          <span class="required">*</span>
        </h4>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="contact-input-container">
        <h4>Subject</h4>
        <input
          type="text"
          name="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className="contact-input-container">
        <h4>
          Message
          <span class="required">*</span>
        </h4>
        <textarea
          id="message-field"
          name="message"
          rows="19"
          cols="40"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </div>
      <div className="contact-input-container">
        <button type="submit" className="tabContent-button">
          Submit
        </button>
      </div>
*/}
    </form>
  );
}

export default Contact;

import React from "react";
import { Instagram, GitHub, LinkedIn, ExpandLess } from "@material-ui/icons";
function Footer() {
  return (
    <div className="footer">
      <div className="footer-arrow-up">
        <ExpandLess onClick={() => window.scrollTo(0, 0)} id="expandicon" />
      </div>
      <div className="footer-social-icons-container">
        <a href="https://www.instagram.com/iamritikpal/">
          <Instagram className="footer-social-icons" />
        </a>

        <a href="https://www.linkedin.com/in/ritik-pal-1a8255168/">
          <LinkedIn className="footer-social-icons" />
        </a>
        <a href="https://github.com/RitikPal98">
          <GitHub className="footer-social-icons" />
        </a>
      </div>
      <p>Made with love by Ritik Pal ♥️</p>
    </div>
  );
}

export default Footer;
